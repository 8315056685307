import React, { useState, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { CardHeader, Table, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getColumns } from 'src/components/TableColumn/SalesColumn';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CancelOutlined } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import '../assets/css/SalesPromotion.css';
import { useNavigate } from 'react-router-dom';
import { fetchEventListthunkAction } from 'src/redux/Event/action';
import { selectEvents } from 'src/redux/Event/selector';
import SearchBarMui from 'src/components/Search/SearchBarMui';
import TuneIcon from '@mui/icons-material/Tune';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Scrollbar from 'src/components/scrollbar';
import { TablesPaginator } from 'src/components/TablesPaginator/TablesPaginator';
import { changeStatusService, fetchTargetServices, setEventListSequenceApi } from 'src/services/EventServices';
import toast from 'react-hot-toast';
import TransitionsModalWithoutScroll from 'src/components/TransitionModal/TransitionModalWithoutScroll';
import { RedDot } from '../components/RedDot';
import { StatusButton } from '../components/StatusButton';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableTableRow } from 'src/components/DraggableComponents/DraggableTableRow';
import { StaticTableRow } from 'src/components/DraggableComponents/StaticTableRow';
import SimpleBackdrop from 'src/components/Backdrop';
import { DateRangerFilterV2 } from 'src/components/DateRangeFilterV2';
import moment from 'moment';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 50;

export default function DashboardAppPageListing() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categorySpinner, setCategorySpinner] = React.useState(false);
  const [subPromotionTarget, setSubPromotionTarget] = React.useState([]);
  const [sequenceChanged, setSequenceChanged] = React.useState(false);
  const [sequenceLoader, setSequenceLoader] = React.useState(false);
  const itemDiscountTypeList = [
    {
      id: 1,
      name: 'Scan Data',
      value: 'scan_data',
    },
    {
      id: 2,
      name: 'Coupon',
      value: 'coupon',
    },
    {
      id: 3,
      name: 'Retailer Funded',
      value: 'retailer_funded',
    },
    {
      id: 4,
      name: 'Other Manufacture',
      value: 'other_manufacture',
    },
    {
      id: 5,
      name: 'Manufacture',
      value: 'manufacture',
    },
    {
      id: 6,
      name: 'Loyalty',
      value: 'loyalty',
    },
  ];
  const promotionTypeList = [
    {
      id: 1,
      name: 'Item Discount',
      value: 'item_discount',
    },
    {
      id: 2,
      name: 'Mix & Match',
      value: 'mix_and_match',
    },
    {
      id: 3,
      name: 'BOGO',
      value: 'bogo',
    },
    {
      id: 4,
      name: 'Single Item',
      value: 'single_item',
    },
    {
      id: 5,
      name: 'Custom List',
      value: 'custom_list',
    },
  ];
  const [promotionTargetList] = React.useState([
    {
      id: 1,
      name: 'Department',
      value: 'department',
    },
    {
      id: 2,
      name: 'Category',
      value: 'category',
    },
    {
      id: 3,
      name: 'Sub Category',
      value: 'sub_category',
    },
    {
      id: 4,
      name: 'Item Group',
      value: 'item_group',
    },
    {
      id: 5,
      name: 'Distributor',
      value: 'distributor',
    },
    {
      id: 6,
      name: 'Item Size',
      value: 'item_size',
    },
    {
      id: 7,
      name: 'Item Pack',
      value: 'item_pack',
    },
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [datePickerRangeState, setDatePickerRangeState] = React.useState([null, null]);
  const { events, isLoading, totalPage, count } = useSelector(selectEvents);
  const [applied, setApplied] = React.useState(false);
  const [active, setActive] = React.useState('');
  const [type, setType] = React.useState('');

  const [promotionTarget, setPromotionTarget] = React.useState('');
  const [itemDiscountType, setItemDiscountType] = React.useState('');
  const [targetType, setTargetType] = React.useState('');
  const [targetName, setTargetName] = React.useState('');

  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [scanDataSwitch, setScanDataSwitch] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [activeId, setActiveId] = useState();
  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();
  const [numSelected, setNumSelected] = React.useState([]);

  const [sort, setSort] = useState(null);
  const [eventsData, setEventsData] = React.useState(events);

  const token = localStorage.getItem('access_token');
  React.useEffect(() => {
    if (events !== undefined) {
      setEventsData(events);
    }
  }, [events, events?.length]);

  const changeStatusEvent = async (status, id) => {
    try {
      setLoader(true);
      const response = await changeStatusService(id, status);
      if (response.status === 201) {
        toast.success(response.data.message);
        setLoader(false);
        dispatch(
          fetchEventListthunkAction(
            {
              search: globalFilter || '',
              limit: pageSize,
              page: pageIndex,
              status: active,
              type: targetType,
              typeName: targetName,
              promo: promotionTarget,
              startDate: applied ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
              endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
              discount: itemDiscountType,
              filterApplied: true,
              // scanData: scanDataSwitch,
              sort: sort?.sortBy || undefined,
              order: sort?.sortDirection || undefined,
            },
            onSuccess,
            onError
          )
        );
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.log(err);
    }
  };

  const data = React.useMemo(() => eventsData, [eventsData]);
  const items = useMemo(() => eventsData?.map(({ id }) => id), [eventsData]);
  const columns = React.useMemo(() => getColumns(changeStatusEvent), [index, numSelected, eventsData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    setSortBy,
    gotoPage,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (keyword) => {
    gotoPage(0);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  const changeStatus = async (status) => {
    setFilterApplied(true);
    setOpenFilter(false);
    setNumSelected([]);
    if (pageIndex === 0) {
      dispatch(
        fetchEventListthunkAction(
          {
            search: globalFilter || '',
            limit: pageSize,
            page: pageIndex,
            status: active,
            type: targetType,
            typeName: targetName,
            promo: promotionTarget,
            startDate: applied ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
            endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
            discount: itemDiscountType,
            filterApplied: true,
            // scanData: scanDataSwitch,
            sort: sort?.sortBy || undefined,
            order: sort?.sortDirection || undefined,
          },
          onSuccess,
          onError
        )
      );
    } else gotoPage(0);
    handleClose();
  };

  const resetFilter = async () => {
    setFilterApplied(false);
    setTargetName('');
    setTargetType('');
    setPromotionTarget('');
    setItemDiscountType('');
    setDatePickerRangeState([null, null]);
    setActive('');
    setScanDataSwitch(false);
    setNumSelected([]);
    if (pageIndex === 0) {
      dispatch(
        fetchEventListthunkAction(
          {
            search: globalFilter || '',
            limit: pageSize,
            page: 0,
            status: '',
            type: '',
            typeName: '',
            promo: '',
            startDate: '',
            endDate: '',
            discount: '',
            filterApplied: false,
            sort: sort?.sortBy || undefined,
            order: sort?.sortDirection || undefined,
          },
          onSuccess,
          onError
        )
      );
    } else gotoPage(0);
    handleClose();
  };

  React.useEffect(() => {
    if (token) {
      setNumSelected([]);
      dispatch(
        fetchEventListthunkAction(
          {
            search: globalFilter || '',
            limit: pageSize,
            page: pageIndex,
            status: active,
            type: targetType,
            typeName: targetName,
            promo: promotionTarget,
            startDate: applied ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
            endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
            discount: itemDiscountType,
            filterApplied: true,
            // scanData: scanDataSwitch,
            sort: sort?.sortBy || undefined,
            order: sort?.sortDirection || undefined,
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, sort, pageSize, token, globalFilter]);

  const goToCustomPage = (value) => {
    return gotoPage(value);
  };

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setSequenceChanged(true);
      setEventsData((item) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(item, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = page.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, page, prepareRow]);

  const makeSequence = async () => {
    let arr = [];
    for (let i = 0; i < eventsData.length; i += 1) {
      const obj = {
        sequenceNumber: pageIndex * 10 + (i + 1),
        promotionId: Number(eventsData[i].id),
      };
      arr.push(obj);
    }
    setSequenceChanged(false);
    setSequenceLoader(true);
    const promise = await setEventListSequenceApi(arr);

    setSequenceLoader(false);
  };

  const applyFilter = async () => {
    try {
      setFilterApplied(true);
      setOpenFilter(false);
      setNumSelected([]);
      if (pageIndex === 0) {
        dispatch(
          fetchEventListthunkAction(
            {
              search: globalFilter || '',
              limit: pageSize,
              page: pageIndex,
              status: active,
              type: targetType,
              typeName: targetName,
              promo: promotionTarget,
              startDate: applied ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
              endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
              discount: itemDiscountType,
              filterApplied: true,
              // scanData: scanDataSwitch,
              sort: sort?.sortBy || undefined,
              order: sort?.sortDirection || undefined,
            },
            onSuccess,
            onError
          )
        );
      } else gotoPage(0);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.Name,
    limit: 1500,
  });

  React.useMemo(() => {
    const fetchTargets = async () => {
      try {
        setCategorySpinner(true);
        setTargetName(null);
        setSubPromotionTarget([]);
        const typeD = promotionTargetList?.find((item) => item.value === targetType?.toLowerCase())?.value;
        const response = await fetchTargetServices(typeD);
        const { data } = response.data;
        setSubPromotionTarget(data);
        setCategorySpinner(false);
      } catch (err) {
        setCategorySpinner(false);
        console.log(err);
      }
    };
    fetchTargets();
  }, [targetType]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Retailz POS </title>
      </Helmet>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        pb={1}
        style={{ position: 'fixed', top: '0', right: '100px', zIndex: '9999' }}
      >
        <SearchBarMui
          keyword={globalFilter}
          setKeyword={setGlobalFilter}
          title="Sales & Promotion"
          onSubmitHandler={onSubmitHandler}
        />
      </Stack>
      <Container style={{ width: '95%', maxWidth: '1500px', margin: 'auto', marginTop: '-30px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6" style={{ padding: '0', margin: '0' }}>
            Sales & Promotions
          </Typography>
        </Stack>
      </Container>
      <SimpleBackdrop open={sequenceLoader} />
      <Container fluid style={{ width: '95%', maxWidth: '1500px', margin: 'auto' }}>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <Card style={{ padding: '15px', borderRadius: '0' }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <CardHeader>
                  <Row>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
                      <Stack direction="row">
                        <Button
                          size="small"
                          variant="contained"
                          style={{ borderRadius: '3px' }}
                          onClick={() => navigate('/sales-and-promotion/create')}
                        >
                          <AddCircleOutlineOutlinedIcon /> Add
                        </Button>
                      </Stack>
                      <Stack direction="row">
                        <Button
                          size="small"
                          variant="contained"
                          disabled={!sequenceChanged}
                          style={{ borderRadius: '3px' }}
                          onClick={makeSequence}
                        >
                          Save Sequence
                        </Button>
                      </Stack>
                      <Stack>
                        <div className="total_div">
                          Total : <div className="total_div_count">{isLoading ? '0' : count}</div>
                        </div>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        {eventsData && eventsData?.length > 0 && !isLoading && (
                          <TablesPaginator
                            pageIndex={pageIndex + 1}
                            pageCount={pageCount}
                            pageOptions={pageOptions}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            gotoPage={goToCustomPage}
                          />
                        )}
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" ml={2}>
                          <div style={{ display: 'flex', justifyContent: 'space-around', marginRight: '15px' }}>
                            <div
                              aria-describedby={id}
                              style={{
                                borderRadius: '3px',
                                border: '1px solid black',
                                padding: '5px 5px 0',
                                cursor: 'pointer',
                              }}
                              onClick={() => setOpenFilter(true)}
                            >
                              <TuneIcon />
                            </div>
                            {filterApplied && <Button onClick={resetFilter}>Reset</Button>}
                            <TransitionsModalWithoutScroll
                              open={openFilter}
                              handleClose={() => setOpenFilter(false)}
                              title={'Filters'}
                              btnFunc={() => applyFilter(active)}
                              resetFunc={() => {
                                resetFilter();
                                setOpenFilter(false);
                              }}
                              btnTitle={'Submit'}
                              resetBtn={'Reset'}
                            >
                              {categorySpinner ? (
                                <Stack spacing={2} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                                  <RedDot />
                                </Stack>
                              ) : (
                                <div style={{ textAlign: 'left' }}>
                                  <Stack spacing={2} direction={'column'}>
                                    <Stack spacing={2} direction={'row'}>
                                      <div
                                        style={{ maxWidth: '500px', display: 'flex', justifyContent: 'space-between' }}
                                      >
                                        <DateRangerFilterV2
                                          setApply={setApplied}
                                          minWidth={'230px'}
                                          apply={applied}
                                          value={datePickerRangeState}
                                          setValue={setDatePickerRangeState}
                                        />
                                      </div>
                                      <FormControl fullWidth color="primary">
                                        <InputLabel id="demo-simple-select-label" size="small">
                                          Status{' '}
                                        </InputLabel>
                                        <Select
                                          value={active}
                                          label="Status "
                                          onChange={(e) => setActive(e.target.value)}
                                          size="small"
                                          endAdornment={
                                            active !== '' && (
                                              <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => setActive('')}
                                                  edge="end"
                                                >
                                                  {<CancelOutlined />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }
                                        >
                                          <MenuItem value={'ACTIVE'}>Active</MenuItem>
                                          <MenuItem value={'IN-ACTIVE'}>Expire</MenuItem>
                                          <MenuItem value={'UPCOMING'}>Upcoming</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                    <Stack spacing={2} direction={'row'}>
                                      <FormControl fullWidth color="primary">
                                        <InputLabel id="demo-simple-select-label" size="small">
                                          Select Target Type{' '}
                                        </InputLabel>
                                        <Select
                                          value={targetType}
                                          label="Select Target Type "
                                          onChange={(e) => setTargetType(e.target.value)}
                                          size="small"
                                          endAdornment={
                                            targetType !== '' && (
                                              <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => setTargetType('')}
                                                  edge="end"
                                                >
                                                  {<CancelOutlined />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }
                                        >
                                          {promotionTargetList.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      {/* <FormControl fullWidth color="primary">
                                                                            <InputLabel id="demo-simple-select-label" size="small">
                                                                                Select Target Name{' '}
                                                                            </InputLabel>
                                                                            <Select
                                                                                value={targetName}
                                                                                label="Select Target Name "
                                                                                onChange={(e) => setTargetName(e.target.value)}
                                                                                size="small"
                                                                                endAdornment={
                                                                                    targetName !== '' && (
                                                                                        <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                                                                            <IconButton
                                                                                                aria-label="toggle password visibility"
                                                                                                onClick={() => setTargetName('')}
                                                                                                edge="end"
                                                                                            >
                                                                                                {<CancelOutlined />}
                                                                                            </IconButton>
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }
                                                                            >
                                                                                {promotionTargetList.map((item) => (
                                                                                    <MenuItem key={item.id} value={item.value}>
                                                                                        {item.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl> */}
                                      <Autocomplete
                                        id="virtualize-demo"
                                        filterOptions={filterOptions}
                                        sx={{ width: '100%' }}
                                        options={subPromotionTarget || []}
                                        size="small"
                                        disableClearable
                                        value={
                                          targetName
                                            ? subPromotionTarget?.find((option) => option.id === targetName)
                                            : { Name: '' }
                                        }
                                        getOptionLabel={(option) => option.Name}
                                        onChange={(e, v) => setTargetName(v.id)}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Target"
                                            placeholder="Select Target"
                                          />
                                        )}
                                      />
                                    </Stack>
                                    <Stack spacing={2} direction={'row'}>
                                      <FormControl fullWidth color="primary">
                                        <InputLabel id="demo-simple-select-label" size="small">
                                          Select Promotion Type{' '}
                                        </InputLabel>
                                        <Select
                                          value={promotionTarget}
                                          label="Select Promotion Type "
                                          onChange={(e) => setPromotionTarget(e.target.value)}
                                          size="small"
                                          endAdornment={
                                            promotionTarget !== '' && (
                                              <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => setPromotionTarget('')}
                                                  edge="end"
                                                >
                                                  {<CancelOutlined />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }
                                        >
                                          {promotionTypeList.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl fullWidth color="primary">
                                        <InputLabel id="demo-simple-select-label" size="small">
                                          Select Item Discount Target{' '}
                                        </InputLabel>
                                        <Select
                                          value={itemDiscountType}
                                          label="Select Item Discount Target "
                                          onChange={(e) => setItemDiscountType(e.target.value)}
                                          size="small"
                                          endAdornment={
                                            itemDiscountType !== '' && (
                                              <InputAdornment position="end" style={{ marginRight: '20px' }}>
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => setItemDiscountType('')}
                                                  edge="end"
                                                >
                                                  {<CancelOutlined />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }
                                        >
                                          {itemDiscountTypeList.map((item) => (
                                            <MenuItem key={item.id} value={item.value}>
                                              {item.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Stack>
                                    {/* <Stack direction={'row'} justifyContent={'space-between'} pl={1} pt={0}>
                                                                        <p>Scan Data</p>
                                                                        <FormControlLabel
                                                                            style={{ margin: '0' }}
                                                                            control={
                                                                                <Switch
                                                                                    checked={scanDataSwitch}
                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                    onChange={(e) => {
                                                                                        setScanDataSwitch(e.target.checked);
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </Stack> */}
                                  </Stack>
                                </div>
                              )}
                            </TransitionsModalWithoutScroll>
                          </div>
                          <div className="status_div">
                            <StatusButton color={'#198753'} /> Active
                          </div>
                          <div className="status_div">
                            <StatusButton color={'#DC3444'} /> Expire
                          </div>
                          <div className="status_div">
                            <StatusButton color={'#FFC007'} /> Upcoming
                          </div>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Row>
                </CardHeader>
                {isLoading ? (
                  <React.Fragment>
                    <div className="no_posts">
                      <RedDot />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {error ? (
                      <React.Fragment>
                        <div className="no_posts">{error}</div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Table {...getTableProps()} style={{ width: '100%' }}>
                          <TableHead>
                            {headerGroups.map((headerGroup) => (
                              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                                {headerGroup.headers.map((column) => (
                                  <TableCell
                                    style={{
                                      backgroundColor: 'black',
                                      color: 'white',
                                      fontWeight: 'bolder',
                                      padding: '10px 5px 10px 5px',
                                    }}
                                    key={column.id}
                                    onClick={() => {
                                      if (!column.disableSortBy) {
                                        const desc =
                                          column.isSortedDesc === true
                                            ? undefined
                                            : column.isSortedDesc === false
                                            ? true
                                            : false;
                                        setSort({
                                          sortBy: column.id,
                                          sortDirection: desc ? 'desc' : 'asc',
                                        });
                                        setSortBy([{ id: column.id, desc }]);
                                      }
                                    }}
                                    {...column.getHeaderProps()}
                                  >
                                    {column.render('Header')}
                                    <span className="mx-2">
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <i className="bi bi-caret-down-fill " />
                                        ) : (
                                          <i className="bi bi-caret-up-fill " />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableHead>
                          <TableBody {...getTableBodyProps()}>
                            <SortableContext items={data} strategy={verticalListSortingStrategy}>
                              {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                  <>
                                    <DraggableTableRow
                                      id="table_tr"
                                      key={row.original.id}
                                      row={row}
                                      changeStatusEvent={changeStatusEvent}
                                    />
                                  </>
                                );
                              })}
                            </SortableContext>
                          </TableBody>
                        </Table>
                        <DragOverlay>
                          {activeId && (
                            <Table style={{ width: '100%' }}>
                              <TableBody>
                                <StaticTableRow row={selectedRow} />
                              </TableBody>
                            </Table>
                          )}
                        </DragOverlay>
                        {!error && eventsData?.length === 0 && <div className="no_posts">No Data Found !</div>}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </TableContainer>
            </Scrollbar>
          </Card>
        </DndContext>
      </Container>
    </>
  );
}
