import { FETCH_STORES_FAILURE, FETCH_STORES_REQUEST, FETCH_STORES_SUCCESS } from './type';
import { getAllStoresServices } from 'src/services/Stores.services';

export const fetchStoresRequest = () => {
  return {
    type: FETCH_STORES_REQUEST,
  };
};

export const fetchStoresSuccess = (stores) => {
  return {
    type: FETCH_STORES_SUCCESS,
    payload: { stores },
  };
};

export const fetchStoresFailure = (error) => {
  return {
    type: FETCH_STORES_FAILURE,
    payload: error,
  };
};

export const fetchStoresListthunkAction = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchStoresRequest());
      const { data } = await getAllStoresServices();
      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchStoresSuccess(data?.data));
    } catch (error) {
      dispatch(fetchStoresFailure(error.message));
    }
  };
};
