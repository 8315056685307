import React, { useState } from 'react';
import { Table, Container } from 'reactstrap';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import Card from '@mui/material/Card';
import '../../assets/css/SalesPromotion.css';
import { CircularProgress, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { getColumns } from '../TableColumn/ItemSearchColumn';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 50;

export default function ItemTables({ events, isLoading, totalPage, itemListChecked, setItemListChecked, unitPrice }) {
  const [error, setError] = React.useState(null);
  const [index, setIndex] = React.useState();

  const [sort, setSort] = useState(null);

  const data = React.useMemo(() => events, [events]);
  const columns = React.useMemo(
    () => getColumns(itemListChecked, setItemListChecked),
    [index, itemListChecked, setItemListChecked, unitPrice]
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    setSortBy,
    gotoPage,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };
  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  return (
    <>
      <Container>
        <Card>
          <Scrollbar>
            <TableContainer>
              {isLoading ? (
                <React.Fragment>
                  <div className="no_posts">
                    <CircularProgress />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="no_posts">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table {...getTableProps()} style={{ width: '100%' }}>
                        <TableHead>
                          {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                              {headerGroup.headers.map((column) => (
                                <TableCell
                                  style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    padding: '0px 0px 0px 16px',
                                  }}
                                  key={column.id}
                                  onClick={() => {
                                    if (!column.disableSortBy) {
                                      const desc =
                                        column.isSortedDesc === true
                                          ? undefined
                                          : column.isSortedDesc === false
                                          ? true
                                          : false;
                                      setSort({
                                        sortBy: column.id,
                                        sortDirection: desc ? 'desc' : 'asc',
                                      });
                                      setSortBy([{ id: column.id, desc }]);
                                    }
                                  }}
                                  {...column.getHeaderProps()}
                                >
                                  {column.render('Header')}
                                  <span className="mx-2">
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <i className="bi bi-caret-down-fill " />
                                      ) : (
                                        <i className="bi bi-caret-up-fill " />
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </span>
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <TableRow {...row.getRowProps()} key={row.id}>
                                {row.cells.map((cell) => {
                                  return (
                                    <TableCell
                                      style={{
                                        padding: '0px 0px 0px 16px',
                                      }}
                                      className="px-4 py-2"
                                      key={cell.id}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render('Cell')}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      {!error && events?.length === 0 && <div className="no_posts">No Data Found !</div>}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
