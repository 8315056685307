import React from 'react';

export const RedDot = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <svg height="100" width="100" className="blinking">
        <circle cx="50" cy="50" r="10" fill="#DC3444" />
      </svg>
    </div>
  );
};
