import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { generateInvalidMsg, generateRequiredMsg } from 'src/utils/validMsgGenerator';
import '../../assets/css/bogo.css';
import { getColumns } from 'src/components/TableColumn/BogoModalColumn';
import { Button, Checkbox, FormHelperText } from '@mui/material';
import FormTables from '../FormTables';
import { LoadingButton } from '@mui/lab';
import { fetchItemSearchServices } from 'src/services/Stores.services';
import { BogoModalTable } from '../BogoModalTable';
import XLTransitionsModal from '../XLTransitionModal';
import CustomPopUp from '../TransitionModal/CustomPopUp';

export const SingleItemCombo = (props) => {
  const {
    formDetails,
    formError,
    setFormDetails,
    disabled,
    setFormError,
    comboItemArr,
    isSingleItem,
    setIsSingleItem,
    setComboItemArr,
    setItemTarget,
  } = props;
  const [openModal, setOpenModal] = React.useState(false);
  const [spinner, setSpinner] = React.useState(true);
  const [openModalList, setOpenModalList] = React.useState([]);
  const [openPopup, setOpenPopUp] = React.useState(false);
  const [popUpMsg, setPopUpMsg] = React.useState({
    title: 'Alert',
    subtitle: 'Are you sure you wish to Save Promotion Details.',
    confirmBox: true,
  });

  const handleChange = (e, prop) => {
    if (prop === 'quantity' || prop === 'limitQuantity' || prop === 'buy' || prop === 'get') {
      setFormDetails((current) => ({
        ...current,
        [prop]: e,
      }));
    } else if (prop === 'getCheckbox') {
      setFormDetails((current) => ({
        ...current,
        [prop]: e,
      }));
    } else if (prop === 'discountValue') {
      setFormDetails((current) => ({
        ...current,
        [prop]:
          e.target.value.indexOf('.') >= 0
            ? e.target.value.substr(0, e.target.value.indexOf('.')) +
              e.target.value.substr(e.target.value.indexOf('.'), 3)
            : e.target.value,
      }));
    } else
      setFormDetails((current) => ({
        ...current,
        [prop]: e.target.value,
      }));

    if (prop === 'discountType') {
      if (formDetails.discountType) {
        setFormDetails({
          ...formDetails,
          discountType: e.target.value,
        });
      }
      setFormError((current) => ({
        ...current,
        [prop]: e.target.value === '',
      }));
    } else if (prop === 'quantity' || prop === 'buy' || prop === 'get') {
      setFormError((current) => ({
        ...current,
        [prop]: e === '' || e < 0,
      }));
    } else if (prop === 'getCheckbox') {
      setFormError((current) => ({
        ...current,
        getItem: false,
      }));
    } else if (prop === 'limitQuantity')
      setFormError((current) => ({
        ...current,
        [prop]: e === '' || e % formDetails.quantity !== 0,
      }));
    else
      setFormError((current) => ({
        ...current,
        [prop]: e.target.value < 0,
      }));
  };

  React.useMemo(() => {
    setFormDetails({
      ...formDetails,
      quantity: Number(formDetails.buy) + Number(formDetails.get),
    });
  }, [formDetails.buy, formDetails.get]);

  const itemSearch = async (value) => {
    try {
      setSpinner(true);
      const response = await fetchItemSearchServices(value);
      setOpenModalList(response?.data?.data || []);
      setSpinner(false);
    } catch (err) {
      console.log(err);
      setSpinner(false);
    }
  };

  React.useEffect(() => {
    if (openModal) itemSearch('');
  }, [openModal]);

  const openSearchModal = () => {
    setOpenModal(true);
  };

  const [singleItemComboMode, setSingleItemComboMode] = React.useState(null);

  const updateItemDetails = (item) => {
    if (!singleItemComboMode) {
      setFormDetails((current) => ({
        ...current,
        scanItem: '',
        sku: item?.SKU || 0,
        itemName: item.Name,
        pack: item?.Pack || 0,
        itemSize: item?.Size || 0,
        rPrice: item?.RegularPrice || 0,
        cost: item?.Cost || 0,
      }));
      setFormError({
        ...formError,
        buyItem: false,
      });
    } else {
      setComboItemArr((prevItems) => {
        return prevItems.map((targetItem) => {
          if (targetItem.id === singleItemComboMode) {
            return {
              ...targetItem,
              scanItem: '',
              sku: item?.SKU || 0,
              pack: item?.Pack || 0,
              itemSize: item?.Size || 0,
              itemName: item.Name,
              rPrice: item?.RegularPrice || 0,
              cost: item?.Cost || 0,
            };
          } else {
            return targetItem;
          }
        });
      });
    }

    setOpenModal(false);
    setItemTarget(item.id);
  };

  const addMoreTarget = () => {
    if (formDetails.get === comboItemArr.length) {
      setOpenPopUp(true);
      setPopUpMsg({
        title: 'Alert',
        subtitle: 'The No of target Get items must be equal to Get!',
        confirmBox: false,
      });
      return;
    }
    setComboItemArr((currentObj) => [
      ...currentObj,
      {
        id: uuidv4(),
        scanItem: '',
        sku: 0,
        itemName: '',
        rPrice: 0,
        cost: 0,
      },
    ]);
  };

  const removeTargetItem = (id) => {
    const arr = comboItemArr;
    setComboItemArr(arr.filter((item) => item.id !== id));
  };

  const resetTargetItem = (id) => {
    setComboItemArr((prevItems) => {
      return prevItems.map((targetItem) => {
        if (targetItem.id === id) {
          return {
            ...targetItem,
            scanItem: '',
            sku: 0,
            itemName: '',
            pack: '',
            itemSize: '',
            rPrice: 0,
            cost: 0,
          };
        } else {
          return targetItem;
        }
      });
    });
  };

  return (
    <Stack direction={'column'} spacing={2} width={'100%'}>
      <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={2} p={0}>
        <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} width={{ xs: '100%', md: '40%' }}>
          <Stack direction={{ xs: 'column', md: 'row' }} style={{ width: '100%' }} spacing={2}>
            <TextField
              color="primary"
              label="Buy *"
              size="small"
              disabled={isSingleItem}
              value={formDetails?.buy || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'buy')}
              error={formError.buy}
              helperText={formError.buy && generateRequiredMsg('Buy')}
            />
            <TextField
              color="primary"
              label="Get *"
              disabled={isSingleItem}
              size="small"
              value={formDetails?.get || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'get')}
              error={formError.get}
              helperText={formError.get && generateRequiredMsg('Get')}
            />
          </Stack>
        </Stack>
        <Stack direction={'column'} spacing={2} p={0} width={{ xs: '100%', md: '60%' }}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <TextField
              color="primary"
              label="Total Quantity"
              size="small"
              value={
                isSingleItem ? formDetails.quantity || '' : Number(formDetails.buy) + Number(formDetails.get) || ''
              }
              disabled={!isSingleItem}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'quantity')}
              error={formError.quantity}
              helperText={formError.quantity && generateRequiredMsg('Quantity')}
            />
            <TextField
              color="primary"
              label="Limit Quantity"
              disabled={formDetails.discountType === 'FREE'}
              size="small"
              value={formDetails?.limitQuantity || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'limitQuantity')}
              error={formError.limitQuantity}
              helperText={'Limit Quantity Must Be Multiplier of Quantity!'}
            />
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <FormControl color="primary" error={formError.discountType} style={{ width: '100%' }}>
              <InputLabel id="demo-multiple-checkbox-label" size="small">
                Select Discount Type
              </InputLabel>
              <Select
                value={formDetails.discountType}
                label="Select Discount Type"
                size="small"
                // disabled={comboItemArr.length > 0}
                error={formError.discountType}
                onChange={(e) => handleChange(e, 'discountType')}
              >
                {!isSingleItem && <MenuItem value={'FREE'}>Free</MenuItem>}
                <MenuItem value={'AMOUNTOFF'}>Amount Off</MenuItem>
                <MenuItem value={'PRICE'}>Amount</MenuItem>
                <MenuItem value={'PERCENTAGE'}>Percentage</MenuItem>
              </Select>
              {formError.discountType && <FormHelperText>{generateRequiredMsg('Discount Type')}</FormHelperText>}
            </FormControl>
            <TextField
              color="primary"
              type={'number'}
              label="Discount Value"
              disabled={formDetails.discountType === 'FREE'}
              size="small"
              value={formDetails?.discountValue || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'discountValue')}
              error={
                formError.discountValue ||
                (formDetails.discountType === 'PERCENTAGE' && formDetails.discountValue !== ''
                  ? Number(formDetails.discountValue) < 1 || Number(formDetails.discountValue) > 100
                  : false)
              }
              helperText={
                (formError.discountValue ||
                  (formDetails.discountType === 'PERCENTAGE' && formDetails.discountValue !== ''
                    ? Number(formDetails.discountValue) < 1 || Number(formDetails.discountValue) > 100
                    : false)) &&
                (formDetails.discountValue === ''
                  ? generateRequiredMsg('Discount Value')
                  : generateInvalidMsg('Discount Value'))
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <fieldset className="add_sales_fieldSet">
        <legend className="add_sales_fieldSet_legend_outlined">Select Item (Buy)</legend>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          width={'100%'}
          alignItems={'baseline'}
          spacing={2}
          justifyContent={'space-between'}
        >
          <Stack spacing={2} direction={'row'} width={{ xs: '100%', md: '25%' }} alignItems={'baseline'}>
            {formDetails.sku ? (
              <Button
                onClick={() =>
                  setFormDetails((current) => ({
                    ...current,
                    scanItem: '',
                    sku: '',
                    itemName: '',
                    rPrice: '',
                    cost: '',
                  }))
                }
                style={{ width: '100%', borderRadius: '3px' }}
                variant="contained"
              >
                Remove
              </Button>
            ) : (
              <Button
                disabled={disabled}
                onClick={() => {
                  openSearchModal();
                  setSingleItemComboMode(null);
                }}
                style={{ width: '100%', borderRadius: '3px' }}
                variant="contained"
              >
                Search
              </Button>
            )}
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={'space-between'}
            spacing={2}
            width={{ xs: '100%', md: '75%' }}
          >
            <TextField
              color="primary"
              label="SKU"
              size="small"
              disabled={true}
              value={formDetails?.sku || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'sku')}
            />
            <TextField
              color="primary"
              label="Item Name "
              size="small"
              disabled={true}
              value={formDetails?.itemName || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'itemName')}
            />
            <TextField
              color="primary"
              label="Item Size "
              size="small"
              disabled={true}
              value={formDetails?.itemSize || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'itemSize')}
            />
            <TextField
              color="primary"
              label="Pack "
              size="small"
              disabled={true}
              value={formDetails?.pack || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'pack')}
            />
            <TextField
              color="primary"
              label="Regular Price ($)"
              size="small"
              disabled={true}
              value={formDetails?.rPrice || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'rPrice')}
            />
            <TextField
              color="primary"
              label="Cost ($) "
              size="small"
              disabled={true}
              value={formDetails?.cost || ''}
              style={{ width: '100%' }}
              onChange={(e) => handleChange(e, 'cost')}
            />
          </Stack>
        </Stack>
        {formError.buyItem && <FormHelperText error={true}>Enter Buy Item!</FormHelperText>}
      </fieldset>
      {comboItemArr.map((comboObj, index) => (
        <fieldset className="add_sales_fieldSet" key={index}>
          <legend className="add_sales_fieldSet_legend_outlined">
            {index === 0 && (
              <Checkbox
                checked={formDetails.getCheckbox}
                onChange={(e) => handleChange(e.target.checked, 'getCheckbox')}
              />
            )}
            Target Item (Get)
          </legend>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            width={'100%'}
            alignItems={'baseline'}
            spacing={2}
            justifyContent={'space-between'}
          >
            <Stack spacing={2} direction={'row'} width={{ xs: '100%', md: '25%' }} alignItems={'center'}>
              {comboObj.sku ? (
                <Button
                  onClick={() => resetTargetItem(comboObj?.id)}
                  style={{ width: '100%', borderRadius: '3px' }}
                  variant="contained"
                >
                  Remove
                </Button>
              ) : (
                <Button
                  disabled={!formDetails.getCheckbox}
                  onClick={() => {
                    openSearchModal();
                    setSingleItemComboMode(comboObj.id);
                  }}
                  style={{ width: '100%', borderRadius: '3px' }}
                  variant="contained"
                >
                  Search
                </Button>
              )}
              {index === 0 && (
                <Button
                  onClick={addMoreTarget}
                  disabled={!formDetails.getCheckbox}
                  style={{ width: '100%', borderRadius: '3px' }}
                  variant="contained"
                >
                  <AddCircleOutlineOutlinedIcon />
                </Button>
              )}
              {index !== 0 && (
                <Button
                  onClick={() => removeTargetItem(comboObj.id)}
                  style={{ width: '100%', borderRadius: '3px' }}
                  variant="contained"
                >
                  <RemoveCircleOutlineOutlinedIcon />
                </Button>
              )}
            </Stack>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={'space-between'}
              spacing={2}
              width={{ xs: '100%', md: '75%' }}
            >
              <TextField
                color="primary"
                label="SKU"
                size="small"
                disabled={true}
                value={comboObj?.sku || ''}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'sku')}
              />
              <TextField
                color="primary"
                label="Item Name "
                size="small"
                disabled={true}
                value={comboObj?.itemName || ''}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'itemName')}
              />
              <TextField
                color="primary"
                label="Item Size "
                size="small"
                disabled={true}
                value={comboObj?.itemSize || ''}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'itemSize')}
              />
              <TextField
                color="primary"
                label="Pack "
                size="small"
                disabled={true}
                value={comboObj?.pack || ''}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'pack')}
              />
              <TextField
                color="primary"
                label="Regular Price ($)"
                size="small"
                disabled={true}
                value={comboObj?.rPrice || ''}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'rPrice')}
              />
              <TextField
                color="primary"
                label="Cost ($) "
                size="small"
                disabled={true}
                value={comboObj?.cost || ''}
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'cost')}
              />
            </Stack>
          </Stack>
          {formError.getItem && <FormHelperText error={true}>Enter Get Item!</FormHelperText>}
        </fieldset>
      ))}
      <XLTransitionsModal open={openModal} handleClose={() => setOpenModal(false)} title={'Single Item Search'}>
        <div style={{ textAlign: 'center', maxHeight: '60vh', overflow: 'scroll' }}>
          <BogoModalTable
            events={openModalList}
            getColumn={getColumns}
            isLoading={spinner}
            totalPage={1}
            setArr={updateItemDetails}
            fetch={itemSearch}
            key={1}
          />
        </div>
      </XLTransitionsModal>
      <CustomPopUp
        open={openPopup}
        title={popUpMsg.title}
        subtitle={popUpMsg.subtitle}
        handleClose={() => setOpenPopUp(false)}
      >
        <Stack direction="row" justifyContent={'end'}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }}
            onClick={() => setOpenPopUp(false)}
          >
            OK
          </Button>
        </Stack>
      </CustomPopUp>
    </Stack>
  );
};
