import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

export const getColumnsDiscountWithPerUnit = (setPopUpId) => {
  const deleteRow = (deleted) => {
    setPopUpId(deleted?.uuid);
  };

  return [
    {
      Header: 'Discount Type',
      accessor: 'discount_type',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.discount_type === 'PRICE' ? 'AMOUNT' : row?.original?.discount_type || '-';
      },
    },
    {
      Header: 'Value',
      accessor: 'value',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.discount_value || '0';
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.quantity || '0';
      },
    },
    {
      Header: 'QTY Limit',
      accessor: 'qty_limit',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.qty_limit || '-';
      },
    },
    {
      Header: 'Discount Per Unit',
      accessor: 'discount_per_unit',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <Stack direction={'row'} justifyContent={'space-between'}>
            {row?.original?.discount_per_unit === 'true' || row?.original?.discount_per_unit === true
              ? 'True'
              : 'False'}
            <div onClick={() => deleteRow(row?.original)}>
              <CloseIcon color={'error'} style={{ cursor: 'pointer' }} />
            </div>
          </Stack>
        );
      },
    },
  ];
};
