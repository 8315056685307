import moment from 'moment';
import axiosInstance from './AxiosInstance';

export const getSalesSubmitListApi = (id, prms) => {
  const start_date = moment(prms.startDate).utc().format();
  const end_date = moment(prms.endDate).utc().format();
  const params = {
    agencyId: id,
    limit: prms.limit,
    page: prms?.page + 1 || 1,
  };
  if (prms.startDate !== '') {
    params.startDate = start_date;
  }
  if (prms.endDate !== '') {
    params.endDate = end_date;
  }
  return axiosInstance.get('/reports/sales-list', {
    params,
  });
};

export const updateReturnAmountServices = (amount, id) => {
  return axiosInstance.put(`/reports/sales-list${id}`, {
    return_amount: Number(amount),
  });
};

export const successFTPConnectionServices = (FTPDetails) => {
  return axiosInstance.post(`/reports/connection`, {
    host: FTPDetails.server,
    username: FTPDetails.name,
    connection_protocol: FTPDetails?.connectionType,
    password: FTPDetails.password,
  });
};

export const setupConnectionServices = (body, FTPDetails, id, submitReportArr, day, scanDataSwitch) => {
  return axiosInstance.post(`/reports/setup`, {
    store_name: body.storeName,
    street: body.street,
    city: body.city,
    state: body.state,
    submit_report_on: day,
    zip: body.zipCode,
    country: Number(body.country) || 231,
    account_number: (body?.accNo || '').toString(),
    server_name: FTPDetails.server,
    user_name: FTPDetails.name,
    password: FTPDetails.password,
    connection_protocol: FTPDetails?.connectionType,
    report_submit_to: submitReportArr.join(', '),
    agency_id: Number(id),
    submit_with_header: scanDataSwitch,
  });
};

export const updateSetupConnectionServices = (body, FTPDetails, id, submitReportArr, day, scanDataSwitch) => {
  return axiosInstance.put(`/reports/setup${id}`, {
    account_number: (body?.accNo || '').toString(),
    server_name: FTPDetails.server,
    user_name: FTPDetails.name,
    submit_report_on: day,
    connection_protocol: FTPDetails?.connectionType,
    password: FTPDetails.password,
    report_submit_to: submitReportArr.join(', '),
    submit_with_header: scanDataSwitch,
  });
};

export const fetchSetupDetailsServices = (id) => {
  return axiosInstance.get(`/reports/setup${id}`);
};

export const fetchCountriesServices = (id) => {
  return axiosInstance.get(`/reports/countries`);
};

export const fetchReportByID = (id) => {
  return axiosInstance.get(`/reports/data/${id}`);
};
