import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
// @mui
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
// components
import { fetchLoginUserThunkAction } from 'src/redux/user/action';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/iconify';
import { selectUser } from 'src/redux/user/selector';
import '../../../assets/css/loginPage.css';
import { isValidEmail } from 'src/utils/validators';
import { generateInvalidMsg, generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { localStorageEnums } from 'src/assets/constant/LocalStorageEnums';
import { Checkbox, FormControlLabel } from '@mui/material';

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSigning } = useSelector(selectUser);
  const [email, setEmail] = React.useState(window.localStorage.getItem(localStorageEnums.EMAIL) || '');
  const [password, setPassword] = React.useState(window.localStorage.getItem(localStorageEnums.PASSWORD) || '');
  const [rememberMe, setRememberMe] = React.useState(
    window.localStorage.getItem(localStorageEnums.REMEMBER) === 'true' || false
  );
  const [error, setError] = React.useState({
    email: false,
    password: false,
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClick = () => {
    navigate('/', { replace: true });
  };
  const submitHadler = (e) => {
    e.preventDefault();
    if (password.trim() && isValidEmail(email)) {
      dispatch(fetchLoginUserThunkAction({ email: email, password: password, token: null }, handleClick));
      if (rememberMe) {
        window.localStorage.setItem(localStorageEnums.EMAIL, email);
        window.localStorage.setItem(localStorageEnums.PASSWORD, password);
        window.localStorage.setItem(localStorageEnums.REMEMBER, true);
      } else {
        window.localStorage.removeItem(localStorageEnums.EMAIL);
        window.localStorage.removeItem(localStorageEnums.PASSWORD);
        window.localStorage.setItem(localStorageEnums.REMEMBER, false);
      }
    } else {
      setError({
        email: !isValidEmail(email),
        password: !password,
      });
    }
  };

  const handleChange = (prop, value) => {
    if (prop === 'email') {
      setEmail(value);
      setError({
        ...error,
        email: !isValidEmail(value),
      });
    } else {
      setPassword(value);
      setError({
        ...error,
        password: !value.trim(),
      });
    }
  };

  return (
    <>
      <Form role="form" onSubmit={submitHadler}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email address"
            value={email}
            onChange={(e) => handleChange('email', e.target.value)}
            error={error.email}
            helperText={
              error.email ? (email.length === 0 ? generateRequiredMsg('Email') : generateInvalidMsg('Email')) : ''
            }
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => handleChange('password', e.target.value)}
            error={error.password}
            helperText={error.password && generateRequiredMsg('Password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction="column" sx={{ my: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <FormControlLabel
              control={<Checkbox checked={rememberMe} />}
              onChange={(e) => setRememberMe(e.target.checked)}
              label="Remember Me"
            />
          </Stack>
        </Stack>
        <Button className="login_btn" type="submit" disabled={isSigning ? true : false}>
          {isSigning ? 'Signing in...' : 'Sign in'}
        </Button>
      </Form>
    </>
  );
}
