import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigator } from 'src/components/ScanData/Navigator';
import { Icon } from '@iconify/react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import { CardHeader, Table } from 'reactstrap';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import Typography from '@mui/material/Typography';
import '../assets/css/SalesPromotion.css';
import Scrollbar from 'src/components/scrollbar';
import { getColumns } from 'src/components/TableColumn/SalesSubmitListColumn';
import { TablesPaginator } from 'src/components/TablesPaginator/TablesPaginator';
import { selectStores } from 'src/redux/Stores/selector';
import { useDispatch, useSelector } from 'react-redux';
import { FullHeightLoader } from 'src/components/FullHeightLoader';
import { fetchSalesSubmitListthunkAction } from 'src/redux/SalesSubmit/action';
import { selectSalesSubmit } from 'src/redux/SalesSubmit/selector';
import { generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { LoadingButton } from '@mui/lab';
import { fetchReportByID, updateReturnAmountServices } from 'src/services/SalesSubmit.Services';
import swal from 'sweetalert';
import { DateRangerFilterV2 } from 'src/components/DateRangeFilterV2';
import TransitionsModalWithoutScroll from 'src/components/TransitionModal/TransitionModalWithoutScroll';
import { RedDotLoader } from '../components/RedDotLoader';
import { RedDot } from '../components/RedDot';
import '../assets/css/SalesPromotion.css';
import CsvRenderer from 'src/components/CSVReader';
import XLTransitionsModal from 'src/components/XLTransitionModal';
import CSVTransitionsModal from 'src/components/CSVReader/CSVModal';
import { TableFooter } from '@mui/material';

const INITIAL_PAGE_INDEX = 0;
const INITIAL_PAGE_SIZE = 50;

export default function ScanDataSalesSubmit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stores, isLoading } = useSelector(selectStores);
  const { salesSubmits, isFetching, totalPage, timezone } = useSelector(selectSalesSubmit);

  const { id } = useParams();
  const store = stores.find((store) => store.id === id);
  const [eventsData, setEventsData] = React.useState([]);
  const [datePickerRangeState, setDatePickerRangeState] = React.useState([null, null]);

  const [error, setError] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalView, setOpenModalView] = React.useState(false);
  const [openModalCSV, setOpenModalCSV] = React.useState(false);
  const [openModalCSVLoader, setOpenModalCSVLoader] = React.useState(false);
  const [csvData, setCSVData] = React.useState('');
  const [returnAmount, setReturnAmount] = React.useState('');
  const [returnAmountError, setReturnAmountError] = React.useState(false);
  const [openModalID, setOpenModalID] = React.useState(false);

  const handleOpen = (id) => {
    setOpenModalID(id);
    setReturnAmount('');
    setOpenModal(true);
  };
  const clearFilter = async () => {
    setApplied(false);
    setDatePickerRangeState([null, null]);
    dispatch(
      fetchSalesSubmitListthunkAction(
        id,
        {
          search: globalFilter || '',
          limit: pageSize,
          page: pageIndex,
          startDate: '',
          endDate: '',
          sort: sort?.sortBy || undefined,
          order: sort?.sortDirection || undefined,
        },
        onSuccess,
        onError
      )
    );
  };
  const [applied, setApplied] = React.useState(false);

  const handleOpenView = (amount) => {
    setReturnAmount(amount);
    setOpenModalView(true);
  };
  const [index, setIndex] = React.useState();

  const [sort, setSort] = useState(null);
  const [returnAmountSpinner, setReturnAmountSpinner] = useState(false);

  React.useEffect(() => {
    setEventsData(salesSubmits);
  }, [salesSubmits, salesSubmits?.length]);

  const data = React.useMemo(() => eventsData, [eventsData]);
  const columns = React.useMemo(() => getColumns(timezone), [index, timezone]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: INITIAL_PAGE_SIZE,
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage),
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    setSortBy,
    gotoPage,
    state,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };
  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  React.useEffect(() => {
    const getIndex = () => {
      return Number(`${pageIndex * pageSize}`);
    };
    setIndex(getIndex);
  }, [pageIndex, pageSize]);

  React.useEffect(() => {
    if (id) {
      dispatch(
        fetchSalesSubmitListthunkAction(
          id,
          {
            search: globalFilter || '',
            limit: pageSize,
            page: pageIndex,
            startDate: applied ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
            endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
            sort: sort?.sortBy || undefined,
            order: sort?.sortDirection || undefined,
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, sort, pageSize, globalFilter, id]);

  const navigateTo = (url = '') => {
    navigate(`/scandata/${id}${url}`);
  };

  const updateReturnAmount = async () => {
    try {
      if (returnAmount !== '') {
        setReturnAmountSpinner(true);
        const response = await updateReturnAmountServices(returnAmount, openModalID);
        if (response.status === 200)
          swal('Success', response.data.message, 'success').then(() => {
            setOpenModal(false);
            setReturnAmountSpinner(false);
            setOpenModalID('');
            dispatch(
              fetchSalesSubmitListthunkAction(
                id,
                {
                  search: globalFilter || '',
                  limit: pageSize,
                  page: pageIndex,
                  startDate: applied
                    ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')
                    : '',
                  endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
                  sort: sort?.sortBy || undefined,
                  order: sort?.sortDirection || undefined,
                },
                onSuccess,
                onError
              )
            );
          });
      } else {
        setReturnAmountError(returnAmount === '');
      }
    } catch (err) {
      setReturnAmountSpinner(false);
      swal('Error', err.response.data.message, 'error');
    }
  };

  const fetchList = async () => {
    dispatch(
      fetchSalesSubmitListthunkAction(
        id,
        {
          search: globalFilter || '',
          limit: pageSize,
          page: pageIndex,
          startDate: applied ? moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
          endDate: applied ? moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '',
          sort: sort?.sortBy || undefined,
          order: sort?.sortDirection || undefined,
        },
        onSuccess,
        onError
      )
    );
  };

  const handleOpenCSV = async (id) => {
    setOpenModalCSVLoader(true);
    setCSVData('');
    setOpenModalCSV(true);
    const response = await fetchReportByID(id);
    setCSVData(response?.data?.data || '');
    setOpenModalCSVLoader(false);
  };

  return (
    <>
      <Helmet>
        <title> Scan Data | Retailz POS </title>
      </Helmet>
      {isLoading ? (
        <RedDotLoader />
      ) : (
        <Container maxWidth="xl" style={{ margin: 'auto', marginTop: '-30px' }}>
          <Typography variant="h6">{store?.Name}</Typography>
          <Navigator navigateTo={navigateTo} />
          <br />
          <TransitionsModalWithoutScroll
            open={openModal}
            handleClose={() => setOpenModal(false)}
            title={'Return Amount'}
            loader={returnAmountSpinner}
            btnFunc={updateReturnAmount}
          >
            <TextField
              type={'number'}
              color="primary"
              label="Return Amount * "
              size="small"
              value={returnAmount || ''}
              style={{ width: '100%' }}
              onChange={(e) => {
                setReturnAmount(e.target.value);
                setReturnAmountError(e.target.value < 0 || e.target.value === '');
              }}
              error={returnAmountError}
              helperText={returnAmountError && generateRequiredMsg('Return Amount')}
            />
          </TransitionsModalWithoutScroll>
          <TransitionsModalWithoutScroll
            open={openModalView}
            handleClose={() => setOpenModalView(false)}
            hideBtn={true}
            title={'Return Amount'}
          >
            <TextField
              type={'number'}
              color="primary"
              label="Return Amount * "
              size="small"
              disabled
              value={returnAmount || ''}
              style={{ width: '100%' }}
            />
          </TransitionsModalWithoutScroll>

          <fieldset className="add_sales_fieldSet">
            <legend className="add_sales_fieldSet_legend">Sales Submit - Listing Page</legend>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Stack direction={'row'} justifyContent={'space-between'} marginLeft={'auto'} width={'100%'} mb={1}>
                  <TablesPaginator
                    pageIndex={pageIndex + 1}
                    pageCount={pageCount}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    display={eventsData?.length > 0}
                    setPageSize={setPageSize}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoPage={gotoPage}
                  />
                  <div style={{ maxWidth: '500px', display: 'flex', justifyContent: 'space-between' }}>
                    <DateRangerFilterV2
                      setApply={setApplied}
                      apply={applied}
                      value={datePickerRangeState}
                      setValue={setDatePickerRangeState}
                    />
                    {applied && (
                      <>
                        <Button style={{ width: '20%' }} onClick={fetchList}>
                          Show
                        </Button>
                        <Button style={{ width: '20%' }} onClick={clearFilter}>
                          Clear
                        </Button>
                      </>
                    )}
                  </div>
                </Stack>
                {isFetching ? (
                  <React.Fragment>
                    <div className="no_posts">
                      <RedDot />
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {error ? (
                      <React.Fragment>
                        <div className="no_posts">{error}</div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Table {...getTableProps()} style={{ width: '100%' }}>
                          <TableHead>
                            {headerGroups.map((headerGroup) => (
                              <TableRow {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                                {headerGroup.headers.map((column) => (
                                  <TableCell
                                    style={{
                                      backgroundColor: 'black',
                                      color: 'white',
                                      padding: '10px 5px 10px 5px',
                                    }}
                                    key={column.id}
                                    onClick={() => {
                                      if (!column.disableSortBy) {
                                        const desc =
                                          column.isSortedDesc === true
                                            ? undefined
                                            : column.isSortedDesc === false
                                            ? true
                                            : false;
                                        setSort({
                                          sortBy: column.id,
                                          sortDirection: desc ? 'desc' : 'asc',
                                        });
                                        setSortBy([{ id: column.id, desc }]);
                                      }
                                    }}
                                    {...column.getHeaderProps()}
                                  >
                                    {column.render('Header')}
                                    <span className="mx-2">
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <i className="bi bi-caret-down-fill " />
                                        ) : (
                                          <i className="bi bi-caret-up-fill " />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </span>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableHead>
                          <TableBody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);
                              return (
                                <TableRow id="table_tr" {...row.getRowProps()} key={row.id}>
                                  {row.cells
                                    .filter((cell) => cell.column.Header !== 'Total Sales Price')
                                    .map((cell) => {
                                      return (
                                        <TableCell
                                          style={{
                                            padding: '10px 5px 10px 5px',
                                          }}
                                          key={cell.id}
                                          {...cell.getCellProps()}
                                        >
                                          {cell.render('Cell')}
                                        </TableCell>
                                      );
                                    })}
                                  <TableCell
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      padding: '10px 5px 10px 5px',
                                    }}
                                  >
                                    ${Number(row?.original?.TotalSalesPrice || 0).toFixed(2)}
                                    <div
                                      className="actionDiv"
                                      id="table_tr_td"
                                      style={{
                                        marginBottom: '0px',
                                        marginTop: '0px',
                                        width: '60px',
                                        height: '20px',
                                        marginLeft: '5px',
                                      }}
                                    >
                                      <div style={{ display: 'flex', columnGap: '5px' }}>
                                        {row?.original?.ReturnAmount === null ? (
                                          <div
                                            className="actionButton"
                                            style={{
                                              cursor: 'pointer',
                                              backgroundColor: '#ddf5c4',
                                              width: '20px',
                                              height: '20px',
                                            }}
                                            onClick={() => handleOpen(row?.original?.id)}
                                          >
                                            <Icon
                                              icon={'carbon:edit'}
                                              className="actionBtnIcon"
                                              style={{ color: '#5eb507', fontSize: '20px' }}
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            className="actionButton"
                                            style={{
                                              cursor: 'pointer',
                                              backgroundColor: '#d0e5f5',
                                              width: '20px',
                                              height: '20px',
                                            }}
                                            onClick={() => handleOpenView(row?.original?.ReturnAmount)}
                                          >
                                            <Icon
                                              icon={'carbon:view'}
                                              className="actionBtnIcon"
                                              style={{ color: '#065b9c', fontSize: '20px' }}
                                            />
                                          </div>
                                        )}
                                        <div
                                          className="actionButton"
                                          style={{
                                            cursor: 'pointer',
                                            backgroundColor: '#d0e5f5',
                                            width: '20px',
                                            height: '20px',
                                          }}
                                          onClick={() => handleOpenCSV(row?.original?.id)}
                                        >
                                          <Icon icon="ep:list" color="#065b9c" style={{ fontSize: '20px' }} />
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                          {eventsData?.length > 0 && (
                            <TableFooter
                              style={{
                                backgroundColor: '#eaeaea',
                              }}
                            >
                              {footerGroups.map((group) => (
                                <TableRow {...group.getFooterGroupProps()} key={group.id}>
                                  {group.headers.map((column) => (
                                    <TableCell
                                      style={{
                                        padding: '10px 5px 10px 5px',
                                      }}
                                      {...column.getFooterProps()}
                                      key={column.id}
                                    >
                                      {column.render('Footer')}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableFooter>
                          )}
                        </Table>
                        {!error && eventsData?.length === 0 && <div className="no_posts">No Data Found !</div>}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </TableContainer>
            </Scrollbar>
          </fieldset>
          <CSVTransitionsModal open={openModalCSV} handleClose={setOpenModalCSV}>
            <CsvRenderer loader={openModalCSVLoader} csvData={csvData} salesCompany={store?.Name} />
          </CSVTransitionsModal>
        </Container>
      )}
    </>
  );
}
