import React from 'react';
import 'flatpickr/dist/themes/material_red.css';
import Flatpickr from 'react-flatpickr';

export const DateRangerFilter = (props) => {
  const [value, setValue] = React.useState(props.value);
  const fp = React.useRef();
  const handleValueChange = (newValue) => {
    props.setApply(true);
    let specificDate = new Date(newValue[0]);
    specificDate.setDate(specificDate.getDate() - 6);
    setValue([specificDate, newValue[0]]);
    props.setValue([specificDate, newValue[0]]);
    props?.setDateEmptyError();
    fp.current.flatpickr.close();
  };

  React.useEffect(() => {
    if (!props.apply) {
      setValue([null, null]);
    }
  }, [props.apply]);

  return (
    <Flatpickr
      ref={fp}
      style={{
        width: '100%',
        fontSize: '15px',
        minHeight: '32px',
        borderRadius: '5px',
        borderWidth: '1px',
        padding: '5px',
      }}
      value={value}
      options={{
        mode: 'range',
        dateFormat: 'M d, Y',
      }}
      placeholder="Date Range"
      onChange={handleValueChange}
    />
  );
};
