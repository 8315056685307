import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

export const getColumns = (itemListChecked = [], setItemListChecked) => {
  const handleClick = (row) => {
    const itemFound = itemListChecked.find((item) => item.SKU === row.SKU);
    if (itemFound) {
      let arr = [];
      for (let i = 0; i < itemListChecked.length; i += 1) {
        const item = itemListChecked[i];
        if (Number(item.SKU) !== Number(itemFound.SKU)) arr.push(item);
      }
      setItemListChecked([...arr]);
    } else setItemListChecked((currentArr) => [...currentArr, row]);
  };

  return [
    {
      Header: 'Select',
      accessor: 'select',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <Checkbox
              checked={itemListChecked?.find((item) => item.SKU === row.original.SKU)?.SKU || false}
              onChange={() => handleClick(row?.original)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <Typography variant="span" fontWeight="600">
              {row?.original?.name}
            </Typography>
          </>
        );
      },
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.SKU;
      },
    },
    {
      Header: 'Item Name',
      accessor: 'item_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.Name;
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.RegularPrice;
      },
    },
    {
      Header: 'Cost',
      accessor: 'cost',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.Cost;
      },
    },
    {
      Header: 'Size',
      accessor: 'size',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.Size;
      },
    },
    {
      Header: 'Pack',
      accessor: 'pack',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.Pack;
      },
    },
  ];
};
