import { FETCH_SALES_SUBMIT_FAILURE, FETCH_SALES_SUBMIT_REQUEST, FETCH_SALES_SUBMIT_SUCCESS } from './type';

const initialState = {
  token: null,
  isFetching: false,
  error: '',
  salesSubmits: [],
  timezone: new Date().getTimezoneOffset() * -1,
  totalPage: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SALES_SUBMIT_REQUEST:
      return {
        ...state,
        salesSubmits: [],
        isFetching: true,
        timezone: new Date().getTimezoneOffset() * -1,
        totalPage: 0,
      };
    case FETCH_SALES_SUBMIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        salesSubmits: action.payload.salesSubmits,
        totalPage: action.payload.totalpage,
        timezone: action?.payload?.timezone || new Date().getTimezoneOffset() * -1,
        error: '',
      };
    case FETCH_SALES_SUBMIT_FAILURE:
      return {
        ...state,
        isFetching: false,
        salesSubmits: [],
        totalPage: 0,
        timezone: new Date().getTimezoneOffset() * -1,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
