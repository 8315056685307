import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

const CustomTooltip = (props) => {
  return (
    <div className="custom-tooltip">
      <span>Date:</span>
      <span>Value:</span>
    </div>
  );
};

const ApexChartOneLine = ({ salesData = [] }) => {
  const [chartData, setChartData] = React.useState(null);
  const updateData = (timeline) => {
    const today = new Date();
    const endDate = today.getTime();
    setChartData(timeline);
    const oneMonthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    const yearStartDate = new Date(today.getFullYear(), 0, 1);
    const reasonableStartDate = new Date('01 Jan 2000');

    switch (timeline) {
      case 'one_month':
        ApexCharts.exec('sales-chart', 'zoomX', oneMonthAgo.getTime(), endDate);
        break;
      case 'six_months':
        ApexCharts.exec('sales-chart', 'zoomX', sixMonthsAgo.getTime(), endDate);
        break;
      case 'one_year':
        ApexCharts.exec('sales-chart', 'zoomX', oneYearAgo.getTime(), endDate);
        break;
      case 'ytd':
        ApexCharts.exec('sales-chart', 'zoomX', yearStartDate.getTime(), endDate);
        break;
      case 'all':
        ApexCharts.exec('sales-chart', 'zoomX', reasonableStartDate.getTime(), endDate);
        break;
      default:
    }
  };

  const [options, setOptions] = React.useState({
    chart: {
      id: 'sales-chart',
      type: 'area',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      categories: salesData?.x?.data || [],
      labels: {
        formatter: function (val) {
          return new Date(val).toLocaleDateString();
        },
      },
    },
    yaxis: {
      title: {
        text: 'Total Sales Price',
      },
      type: 'sale',
      categories: salesData,
    },

    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  });

  React.useEffect(() => {
    if (salesData.length > 0) {
      setOptions({
        chart: {
          id: 'sales-chart',
          type: 'area',
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
            },
          },
        },
        xaxis: {
          type: 'datetime',
          categories: salesData?.x?.data || [],
          labels: {
            formatter: function (val) {
              return new Date(val).toLocaleDateString();
            },
          },
        },
        yaxis: {
          title: {
            text: 'Total Sales Price',
          },
        },
        //       tooltip: {
        //           custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        //               var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        //               var label = salesData[dataPointIndex].label;
        //               var totalItems = salesData[dataPointIndex].totalItems;
        //               var totalQty = salesData[dataPointIndex].totalQty;
        //               var promoPrice = salesData[dataPointIndex].promoPrice;

        //               var tooltipContent = `
        //     <div className="custom-tooltip">
        //       <span>Date: ${label}</span><br>
        //       <span>Total Items: ${totalItems}</span><br>
        //       <span>Total Quantity: ${totalQty}</span><br>
        //       <span>Promo Price: ${promoPrice}</span>
        //     </div>
        //   `;

        //               return tooltipContent;
        //           },
        //       },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      });
    }
  }, [salesData]);

  return (
    <div>
      <div id="chart">
        <div className="toolbar">
          <button
            id="one_month"
            onClick={() => updateData('one_month')}
            className={chartData === 'one_month' ? 'active' : ''}
          >
            1M
          </button>
          <button
            id="six_months"
            onClick={() => updateData('six_months')}
            className={chartData === 'six_months' ? 'active' : ''}
          >
            6M
          </button>
          <button
            id="one_year"
            onClick={() => updateData('one_year')}
            className={chartData === 'one_year' ? 'active' : ''}
          >
            1Y
          </button>
          <button id="ytd" onClick={() => updateData('ytd')} className={chartData === 'ytd' ? 'active' : ''}>
            YTD
          </button>
          <button id="all" onClick={() => updateData('all')} className={chartData === 'all' ? 'active' : ''}>
            All
          </button>
        </div>
        <div id="chart-timeline">
          <ReactApexChart
            options={options}
            tooltip={{ custom: <CustomTooltip /> }}
            series={salesData?.series || []}
            type="area"
            height={350}
          />
        </div>
      </div>
      <div id="html-dist" />
    </div>
  );
};

export default ApexChartOneLine;
