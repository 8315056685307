// CsvRenderer.js
import { Icon } from '@iconify/react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { company } from 'src/constants/Company';
import { FullHeightLoader } from '../FullHeightLoader';
import Scrollbar from '../scrollbar';

const CsvRenderer = ({ csvData, loader, salesCompany }) => {
  const [tableData, setTableData] = React.useState({
    summary: {
      rows: 0,
      qty: 0,
      sales: 0,
      scanProviderLLC: '',
    },
    headers: [],
    data: [],
  });

  React.useEffect(() => {
    setTableData({
      summary: {
        rows: 0,
        qty: 0,
        sales: 0,
        scanProviderLLC: '',
      },
      headers: [],
      data: [],
    });
    if (!loader && csvData?.data) {
      const rows = csvData?.data?.trim()?.split('\n');
      const dataRow = rows?.slice(salesCompany === company.RJRT ? 1 : 2).map((row) => {
        const values = row?.split(',');
        return rows[salesCompany === company.RJRT ? 0 : 1]?.split(',')?.reduce((object, header, index) => {
          object[header.trim()] = values[index]?.trim();
          return object;
        }, {});
      });
      setTableData({
        summary:
          salesCompany === company.RJRT
            ? {}
            : {
                rows: rows[0]?.split(',')[0] || '',
                qty: rows[0]?.split(',')[1] || '',
                sales: rows[0]?.split(',')[2] || '',
                scanProviderLLC: rows[0]?.split(',')[3] || '',
              },
        headers: rows[salesCompany === company.RJRT ? 0 : 1]?.split(','),
        data: dataRow,
      });
    }
  }, [loader, csvData.data]);

  const handleDownload = (format = '.csv') => {
    let blob = null;
    if (format === '.csv') {
      blob = new Blob([csvData.data], { type: 'text/csv' });
    } else {
      blob = new Blob([csvData.data.replace(/,/g, '|')], { type: 'text/csv' });
    }
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${csvData.fileName}${format}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // Render the table
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: '40px',
          backgroundColor: '#fff',
          columnGap: '20px',
          marginBottom: '10px',
        }}
      >
        {salesCompany !== company.RJRT && (
          <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontWeight: '600' }}>Total rows: {tableData?.summary?.rows || ''}</span>
              <span style={{ fontWeight: '600' }}>Total Qty: {tableData?.summary?.qty || ''}</span>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontWeight: '600' }}>Final Sales: {tableData?.summary?.sales || ''}</span>
              <span style={{ fontWeight: '600' }}>Scan Provider LLC: {tableData?.summary?.scanProviderLLC || ''}</span>
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            width: salesCompany === company.RJRT ? '100%' : '40%',
            columnGap: '20px',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => handleDownload('.txt')}
            style={{ borderRadius: '3px' }}
          >
            <Icon fontSize={'20px'} style={{ marginRight: '10px' }} icon="typcn:download" color="#ffffff" /> Download
            Report To Text
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleDownload('.csv')}
            style={{ borderRadius: '3px' }}
          >
            <Icon fontSize={'20px'} style={{ marginRight: '10px' }} icon="fa6-solid:file-csv" color="#ffffff" /> Export
            Report To CSV
          </Button>
        </div>
      </div>
      {loader ? (
        <FullHeightLoader />
      ) : (
        <div style={{ overflow: 'scroll', width: '100%', height: '100%', maxHeight: '77vh' }}>
          {csvData.data.length > 2 ? (
            <Table style={{ height: '100%' }}>
              <TableHead>
                <TableRow>
                  {tableData.headers?.map((header) => (
                    <TableCell
                      style={{
                        backgroundColor: 'black',
                        color: 'white',
                        minWidth: '200px',
                        border: '1px solid #fff',
                        padding: '10px 5px 10px 5px',
                      }}
                      key={header}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.data?.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {tableData?.headers?.map((header) => (
                      <TableCell
                        style={{
                          padding: '10px ',
                        }}
                        key={header}
                      >
                        {row[header]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div
              style={{ width: '100%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <h4> No Data Found </h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CsvRenderer;
