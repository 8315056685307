import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

export const getColumnsDiscount = (setPopUpId, msg) => {
  const deleteRow = (deleted) => {
    setPopUpId(deleted?.uuid);
  };

  return [
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.quantity || '0';
      },
    },
    {
      Header: 'QTY Limit',
      accessor: 'qty_limit',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.qty_limit || '-';
      },
    },
    {
      Header: 'BOGO Buy',
      accessor: 'bogo_buy',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.bogo_buy || '0';
      },
    },
    {
      Header: 'BOGO Get',
      accessor: 'bogo_get',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.bogo_get || '0';
      },
    },
    {
      Header: 'BOGO DiscType',
      accessor: 'bogo_discountType',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.bogo_discount_type === 'PRICE' ? 'AMOUNT' : row?.original?.bogo_discount_type || ' ';
      },
    },
    {
      Header: 'BOGO DiscValue',
      accessor: 'bogo_discountValue',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <Stack direction={'row'} justifyContent={'space-between'}>
            {row?.original?.bogo_discount_value || '0'}
            <div onClick={() => deleteRow(row?.original)}>
              <CloseIcon color={'error'} style={{ cursor: 'pointer' }} />
            </div>
          </Stack>
        );
      },
    },
  ];
};
